@use 'sass:math';

div{
    &.game_index, &.game_game{
        section.game{
            height: 675px;
            button.button{
                margin: 10px auto;
                &:disabled{
                    background: none !important;
                    border: 1px solid #999999;
                    background-color: #cccccc !important;
                    color: #666666 !important;
                    cursor: auto;
                    opacity: 0.5;
                }
                @media screen and (max-width: 767px) {
                    font-size: 14px!important;
                }
            }
            @media screen and (max-width: 1100px){
                height: 650px;
            }
            @media screen and (max-width: 575px) {
                overflow-x: hidden;
                div.popup.game .popup_content{
                    padding: 10px;
                    padding-top: 50px;
                    width: 100%;
                    hr{
                        margin-top: 20px;
                    }
                    &>div >p{
                        font-size: 13px!important;
                    }
                }
            }
        }
    }
    &.game_game{
        section.game{
            min-height: 820px;
            background-size: cover;
            overflow: hidden;
            @media screen and (max-width: 800px) {
                min-height: 720px;
            }
            @media screen and (max-width: 600px) {
                min-height: 400px;
            }
            .rain-container{
                position: absolute;
                height: 100%;
                left: 0;
                top: 0;
                overflow: hidden;
                div.rain{
                    height: 100%;
                    width: 90%;
                    display: block;
                    margin: auto;
                    position: relative;
                    overflow: hidden;
                    z-index: 2;
                    .top-left-infos{
                        position: absolute;
                        left: 0;
                        top: 30px;
                        display: block;
                        .timer, .score{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                        }
                        span{
                            margin-left: 8px;
                            font-size: 26px;
                            line-height: 48px;
                            color: white;
                            font-weight: bold;
                        }
                        img{
                            max-height: 28px;
                        }
                        &.hide{
                            display: none;
                        }
                    }
                    img.image_drop{
                        position: absolute;
                        top: -50px;
                        transition: all linear 2s;
                        max-height: 120px;
                        max-width: 120px;
                        width: 100%;
                        height: auto;
                        width: auto;
                        z-index: 99;
                        @for $i from 10 through 50 {
                            $randomNumber: random(14);
                            $randomNumber2: random(10);
                            &:nth-child(#{$i}) {
                                top: -120px+(random(90)*-1);
                                left: 0%+random(80);
                                transition: all linear (3s - random(14) / 10);
                                transition-delay: 0.3s + random(10) + $i / 8;
                            }
                        }
                        @for $i from 1 through 10 {
                            $randomNumber: random(14);
                            &:nth-child(#{$i}) {
                                top: -120px+(random(90)*-1);
                                left: 0%+random(80);
                                transition: all linear (3s - calc(random(14) / 10));
                                transition-delay: 0.3s+$i;
                            }
                        }
                    }
                    @media screen and (max-width: 600px) {
                        width: 100%;
                        img{
                            max-width: 90px;
                            max-height: 90px;
                        }
                    }
                }
            }
            &.wishlist{
                height: auto;
                padding: 100px 0 150px 0;
                background-size: 1920px!important;
                .wrapper-recap{
                    background: white;
                    border-radius: 10px;
                    max-width: calc(1123px - (26px * 2));
                    padding: 26px;
                    margin: auto;
                    position: relative;
                    @media screen and (max-width: 1200px){
                        max-width: calc(100% - (48px * 2));
                    }
                    img.absolute{
                        position: absolute;
                        z-index: 1;
                        &.top-left{
                            top: 0;
                            left: 0;
                            max-width: 260px;
                            @media screen and (max-width: 768px){
                                max-width: 200px;
                            }
                            @media screen and (max-width: 600px){
                                max-width: 120px;
                            }
                        }
                        &.bottom-right{
                            bottom: 0;
                            right: 0;
                            max-width: 260px;
                            @media screen and (max-width: 600px){
                                max-width: 150px;
                            }
                        }
                    }
                    .wrapper-elements{
                        z-index: 2;
                        position: relative;
                        @media screen and (max-width: 600px){
                            p{
                                max-width: 200px;
                                margin: auto;
                            }
                        }
                        .recap{
                            display: flex;
                            justify-content: center;
                            flex-wrap: wrap;
                            width: 390px;
                            max-width: 90%;
                            margin: 35px auto;
                            div{
                                margin: 10px;
                                flex: calc(100% / 3 - 20px);
                                background: rgba(255,255,255,0.3);
                                height: 110px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                position: relative;
                                img{
                                    max-width: 80%;
                                    max-height: 80%;
                                }
                                .remove{
                                    color: #071242;
                                    position: absolute;
                                    top: 3px;
                                    right: 5px;
                                    font-size: 16px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                button{
                    font-weight: bold!important;
                }
                @media screen and (max-width: 767px) {
                    padding: 80px 0 150px 0;
                    h1{
                        max-width: 95%;
                        display: block;
                        margin: 10px auto;
                        font-size: 22px!important;
                        br{
                            display: none;
                        }
                        &+p{
                            max-width: 95%;
                            display: block;
                            margin: 10px auto;
                            font-size: 14px!important;
                            br{
                                display: none;
                            }
                        }
                    }
                    .recap + button{
                        font-size: 14px!important;
                        padding: 8px 12px!important;
                    }
                }
                @media screen and (max-width: 600px) {
                    padding: 80px 0 70px 0;
                }
            }
            .wrapper-wishlist{
                background: white;
                border-radius: 10px;
                max-width: 1123px;
                padding: 40px 76px;
                margin: auto;
                margin-top: 28px;
                min-height: 30vh;
                align-items: center;
                display: flex;
                flex-direction: column;
                @media screen and (max-width: 1200px){
                    max-width: calc(100% - ((76px + 24px) * 2));
                }
                @media screen and (max-width: 767px){
                    padding: 20px 38px;
                    max-width: calc(100% - ((36px + 24px) * 2));
                }
                @media screen and (max-width: 500px) {
                    padding: 20px 12px;
                    max-width: calc(100% - ((10px + 24px) * 2)) !important;
                }
                .wishlist-container{
                    width: 100%;
                    max-width: 1100px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    justify-content: center;
                    margin: auto;
                    margin-top: 16px;
                    &.no-product{
                        align-items: center;
                        .wrapper-no-product{
                            display: flex;
                            flex-direction: column;
                            .buttonReset{
                                font-size: 18px;
                                padding: 15px 32px;
                                background-color: #DC1F2B;
                                border: none;
                            }
                        }
                    }
                    .products{
                        cursor: pointer;
                    }
                    &>div.item-product{
                        flex: 0 0 calc(100% / 5 - 20px);
                        padding: 10px;
                        position: relative;
                        .container_image{
                            .absolute-corner-image{
                                position: absolute;
                                left: 0;
                                top: 0;
                                max-width: 70px !important;
                                max-height: 70px !important;
                            }
                        }
                        img{
                            width: 90%;
                            display: block;
                            margin: auto;
                        }
                        p.name-product{
                            font-size: 16px;
                            line-height: 16px;
                            color: white;
                            height: 32px;
                            overflow: hidden;
                            margin-top: 8px;
                            margin-bottom: 4px;
                            font-family: 'Cera_Bold';
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical; 
                        }
                        .ref-product{
                            font-size: 14px;
                            margin-bottom: 16px;
                            font-family: 'Cera_Bold';
                        }
                        button{
                            background: #E6CB60;
                            color: #071242;
                            border: 0;
                            display: block;
                            font-weight: bold;
                            text-align: center;
                            width: 100%;
                            padding: 16px 20px;
                            font-size: 14px;
                            text-transform: uppercase;
                            cursor: pointer;
                            &.blue{
                                background-color: #0069BC !important;
                                color: white !important;
                            }
                            &.purple{
                                background-color: #47296F !important;
                                color: white !important;
                            }
                            &:hover{
                                filter: brightness(0.9);
                            }
                            &:disabled{
                                background: none !important;
                                border: 1px solid #999999;
                                background-color: #cccccc !important;
                                color: #666666 !important;
                                cursor: auto;
                                opacity: 0.5;
                                &:hover{
                                    filter: none;
                                }
                            }
                        }
                        @media screen and (max-width: 1100px){
                            flex: 0 0 calc(100% / 4 - 20px);
                        }
                        @media screen and (max-width: 850px){
                            flex: 0 0 calc(100% / 3 - 20px);
                        }
                        @media screen and (max-width: 650px){
                            padding: 40px 5px;
                            p{
                                font-size: 14px;
                                margin-top: 8px;
                                margin-bottom: 16px;
                            }
                            button{
                                padding: 12px;
                                font-size: 12px;
                            }
                        }
                        @media screen and (max-width: 500px){
                            flex: 0 0 calc(100% / 2 - 20px);
                            p{
                                font-size: 13px;
                                margin-bottom: 8px;
                            }
                            img{
                                width: auto;
                                max-width: 90%;
                                max-height: 140px;
                            }
                        }
                    }
                }
                div.wrapper-pagination .MuiPagination-root{
                    display: flex;
                    justify-content: center;
                }
                div.filters, div.wrapper-pagination{
                    flex-basis: auto;
                    padding: 0;
                    width: 100%;
                    margin-top: 16px;
                    @media screen and (max-width: 500px) {
                        margin-top: 0;
                    }
                }
                div.filters{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    color: black;
                    row-gap: 8px;
                    column-gap: 30px;
                    justify-content: space-between;
                    .filter-wrapper{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        @media screen and (max-width: 768px) {
                            label{
                                font-size: 14px;
                            }
                            select{
                                font-size: 16px;
                            }
                        }
                        @media screen and (max-width: 450px) {
                            flex-direction: column;
                            align-items: flex-start;
                            flex: 1;
                        }
                        label{
                            color: black;
                            font-size: 16px;
                            margin-right: 4px;
                            flex: 1;
                            width: 80px;
                        }
                        select{
                            border: none !important;
                            outline: none !important;
                            font-family: 'Cera_Black_Italic';
                            font-size: 20px;
                            height: 25px;
                            width: 210px;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
            section.endGame{
                display: none;
                img{
                    width: 100%;
                }
                &.overlayImage{
                    display: flex;
                    opacity: 0;
                    touch-action: auto;
                    transition: opacity 1s;
                }
            }
            .overlay-image{
                position: absolute;
                bottom: 0;
                img{
                    width: auto;
                    max-height: 80px;
                    z-index: 100;
                    bottom: -2px;
                    position: absolute;
                    @media screen and (max-width: 600px) {
                        width: auto;
                        max-height: 50px;
                    }
                }
            }
            .game-image{
                width: fit-content;                                
                img{
                    width: 350px;
                    @media screen and (max-width: 500px) {
                        width: 250px;
                    }
                    cursor: pointer;
                    transition: all 0.2s cubic-bezier(.25,1.92,1,1.13);                     
                    &:hover{
                        filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 1));  
                    }              
                }   
                @keyframes anim {
                    0% {
                                                  
                    }
                    50% {
                        transform: scale(1.1);                        
                        filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 1));   
                    }
                    100% {
                        transform: scale(1);                        
                        filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 1));   
                    }
                    
                  }                     
            }
            .game-title{
                position: absolute;
                top: 50px;
            }
            .popup{
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: rgba(39, 39, 233, 0.5);                
                .popup_content{
                    position: absolute;
                    width: 600px; 
                    height: 450px;               
                }
            }
            &.ended{
                height: auto;
                .rain-container{
                    display: none;
                }
                section.endGame{
                    display: flex;
                    @media screen and (max-width: 600px) {
                        height: 400px;
                    }
                    video{
                        width: 100%;
                        max-height: 100%;
                        left: 50%;
                        transform: translate(-50%);
                        position: relative;
                        @media screen and (max-width: 600px) {
                            width: auto;
                        }
                    }
                    &.overlayImage{
                        height: 100%;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        background-color: rgba(0,0,0,0.4);
                        opacity: 1;
                        transition: opacity 1s;
                        img{
                            margin: auto;
                        }
                    }
                }
            }
            div p{
                &>*{
                    font-size: inherit;
                }
            }
        }
        &#game_scratch_image{
            div.scratch-container{
                padding: 16px 0;
                @media screen and (max-width: 900px){
                    h1{
                        font-size: 20px!important;
                    }
                    p{
                        font-size: 14px!important;
                    }
                }
            }
            div.animate_result{
                position: absolute;
                display: flex;
                width: 100%;
                height: 100%;
                top:0;
                left: -100%;
                justify-content: center;
                align-items: center;
                z-index: 10;
                opacity: 0;
                transition: all ease 500ms;
                &.ended{
                    opacity: 1;
                    left: 0;
                }
                img{
                    max-width: 90%;
                    width: 500px;
                }

            }
        }
        &#game_code_finder{
            .popup_content{
                width: 600px;
                max-width: 100%;
                @media screen and (max-width: 500px){
                    h1{
                        font-size: 18px!important;
                    }
                    .custom_div{
                        font-size: 14px!important;
                    }
                    padding-top: 150px!important;
                    padding-bottom: 80px!important;
                }
            }
            .game{
                max-height: inherit;
                height: auto;
            }
            .inputContainer{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                input.code_input{
                    background: #EFF1F1;
                    padding: 8px 16px;
                    margin: 8px;
                    height: auto;
                    font-size: 16px;
                    width: calc(100% / 3 - 50px);
                    @media screen and (max-width: 700px){
                        font-size: 14px;
                    }
                    &.none{
                        border: 1px solid #EFF1F1;
                    }
                    &.checked{
                        border: 1px solid #3CBCED;
                    }
                }
                @media screen and (max-width: 600px){
                    margin-top: 8px;
                    input.code_input{
                        padding: 6px 12px;
                        margin: 2px;
                        font-size: 14px;
                        width: calc(100% / 3 - 30px);
                    }
                }
            }
            .titleListProducts{
                max-width: 90%;
                width: 400px;
                margin: 16px auto;
            }
            .productsContainer{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                max-width: 90%;
                margin: 16px auto;
                .product{
                    width: calc(100% / 4 - 16px);
                    margin: 16px 0;
                    text-decoration: none;
                    cursor: pointer;
                    img{
                        width: 100%;
                    }
                    &:hover img{
                        filter: drop-shadow(0px 0px 16px #FFFFFF);
                    }
                    @media screen and (max-width: 700px){
                        width: calc(100% / 2 - 16px);
                    }
                }
            }
        }
        &#game_quiz{
            &>section{
                padding: 80px 0 40px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                
                &>div.div_game{
                    opacity: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 780px;
                    transition: opacity ease 500ms, min-height ease 1000ms;
                    &.hidden-block{
                        opacity: 0;
                        transition: opacity ease 500ms, min-height ease 1000ms;
                    }
                    h2{
                        text-transform: uppercase;
                        font-size: 36px;
                        line-height: 1.2;
                        color: white;
                        font-weight: bold;
                    }
                    div.container_response{
                        padding: 12px 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        max-width: 700px;
                        margin: 10px auto;
                        div{
                            padding: 0 25px;
                            img{
                                width: 100%;
                                cursor: pointer;
                                &:hover{
                                    filter: drop-shadow(0 0 0.75rem white);
                                }
                            }
                        }
                        &.nb-4{
                            display: flex;
                            flex-wrap: wrap;

                            &>div{
                                flex: calc(50% - 50px);
                            }
                        }
                    }
                    &>button{
                        background: #46AF37;
                        border-radius: 8px;
                        box-shadow: 0px 6px 0 0 #1F8310;
                        color: white;
                        font-size: 16px;
                        font-weight: bold;
                        line-height: 1.2;
                        text-transform: uppercase;
                        border: 0;
                        padding: 12px 20px;
                        display: block;
                        margin: 10px auto 30px;
                        transition: all ease 200ms;
                        cursor: pointer;
                        &:hover{
                            opacity: 0.8;
                        }
                        &[disabled]{
                            background: none !important;
                            border: 1px solid #999999;
                            background-color: #cccccc !important;
                            color: #666666 !important;
                            cursor: auto;
                            opacity: 1;
                            box-shadow: inherit;
                            &:hover{
                                opacity: 1;
                            }
                        }
                    }
                    @media screen and (max-width: 767px) {
                        h2{
                            font-size: 24px!important;
                        }
                        button{
                            padding: 6px 14px!important;
                            font-size: 14px!important;
                        }
                        div.container_response{
                            padding: 10px 0 30px;
                            div{
                                padding: 0 10px;
                            }
                            &.nb-4{
                                display: flex;
                                flex-wrap: wrap;
                                &>div{
                                    flex: calc(50% - 20px);
                                }
                            }
                        }
                    }
                    @media screen and (max-width: 550px) {
                        min-height: 680px;
                    }
                    @media screen and (max-width: 500px) {
                        min-height: 630px;
                    }
                    @media screen and (max-width: 450px) {
                        min-height: 580px;
                    }
                    @media screen and (max-width: 400px) {
                        min-height: 530px;
                        h2{
                            font-size: 20px!important;
                        }
                    }
                }
                &>div.div_result{
                    max-width: 90%;
                    margin-bottom: 40px;
                    div.contain{
                        padding: 30px 100px;
                        h2{
                            font-weight: bold;
                        }
                        p p{
                            font-size: inherit;
                        }
                        button{
                            background: #46AF37;
                            border-radius: 8px;
                            box-shadow: 0px 6px 0 0 #1F8310;
                            color: white;
                            font-size: 16px;
                            font-weight: bold;
                            line-height: 1.2;
                            text-transform: uppercase;
                            border: 0;
                            padding: 12px 20px;
                            display: block;
                            margin: 20px auto 20px!important;
                            cursor: pointer;
                            opacity: 1;
                            transition: all ease 300ms;
                            &:hover{
                                opacity: 0.8;
                            }
                            &[disabled]{
                                background: none !important;
                                border: 1px solid #999999;
                                background-color: #cccccc !important;
                                color: #666666 !important;
                                cursor: auto;
                                opacity: 1;
                                box-shadow: inherit;
                                &:hover{
                                    opacity: 1;
                                }
                            }
                        }
                        div.result{
                            max-width: 490px;
                            width: 90%;
                            margin: auto;
                            img{
                                display: block;
                                max-width: 360px;
                                width: 100%;
                                margin: 30px auto 12px;
                            }
                            p{
                                width: 100%;
                                font-size: 20px;
                                font-weight: bolder;
                                text-align: center;
                            }
                        }
                        @media screen and (max-width: 767px) {
                            padding: 16px;
                            &>p{
                                font-size: 14px!important;
                            }
                            &>h2{
                                font-size: 28px!important;
                            }
                            div.result{
                                img{
                                    margin: 20px auto 12px;
                                }
                                p{
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
                p.copyrightText{
                    font-size: 12px; 
                    color: rgb(255, 255, 255); 
                    text-transform: initial; 
                    line-height: 1.2;
                    text-align: center;
                    position: relative;
                    top: -10px;
                }
                @media screen and (max-width: 767px) {
                    padding: 50px 0 40px;
                    p.copyrightText{
                        font-size: 9px!important;
                    }
                }
            }
            .overlayPopupStart{
                position: absolute; 
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,0.5);
                &>div{
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    button{
                        margin: 16px auto 0; 
                        display: block;
                        cursor: pointer;
                        &:hover{
                            opacity: 0.8;
                        }
                    }
                    &>h2{
                        font-weight: bold;
                    }
                    &>h2, &>p, &>img, &>picture{
                        max-width: 90%;
                        display: block;
                        margin: 10px auto;
                        &>p{
                            font-size: inherit;
                        }
                    }
                    &>img{
                        max-height: 300px;
                    }
                    @media screen and (max-width: 767px) {
                        button{
                            padding: 6px 14px!important;
                            font-size: 14px!important;
                        }
                        p{
                            font-size: 16px!important;
                        }
                    }
                }
            }
        }
    }
}