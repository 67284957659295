.MuiAlert-message {
  padding: 10px 0 !important;
}

body {
  overflow: auto;
  line-height: unset !important;
}

.login-progress {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
}

.login-progress img {
  width: 200px;
  margin-bottom: 8px;
}

.login-progress-text {
  font-weight: bold;
  color: #fff;
  margin-bottom: 32px;
  font-size: 22px;
}

.login-progress-step {
  font-weight: bold;
  color: #fff;
  font-size: 24px;
}
