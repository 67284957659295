.blockContainer{
    display: flex;
    flex-direction: column;
    height: 65vh;
    background: white;
    // padding: 2rem;
    overflow: hidden;
    &.selectCategorieFull{
        width: 100% !important;
    }
    &.selectCategorie, &.selectCategorieFull{
        box-shadow: inherit;
        padding: .5rem;
        width: 500px;
        .rstcustom__rowWrapper{
            &:hover{
                background: #f0faf2;
            }
            cursor: pointer;
        }
        .containerTree{
            .icons{
                fill: #6ed17b !important;
            }
            h5{
                color: #6ed17b;
            }
            .treeContent{
                .rstcustom__row.rst__selected-node{
                    background: #deffe5;
                    span{
                        color: #6ed17b;
                    }
                }

                &.overflowed {
                    overflow: auto;
                }
            }
        }
    }
    .containerButtons{
        // position: absolute;
        // right: 1rem;
        // top: 1rem;
        z-index: 10;
        button{
            border: none;
            background: none;
            position: relative;
            padding: 0;
            cursor: pointer;
            outline: none;
            margin-right: 1rem;
            &:first-child{
                margin-right: 18px;
                &:after{
                    content: "";
                    height: 16px;
                    width: 1px;
                    background: #36234A;
                    position: absolute;
                    right: -10px;
                    top: 0.4rem;
                }
            }
            &:last-child{
                margin-right: 0;
            }
            &:hover{
                h4{
                    text-decoration: underline;
                }
            }
            // h4{
            //     color: #0273A5;
            // }
        }
    }
    .containerTree{
        // padding: 1rem 0;
        height: 100%;
        // background: #FAFBFB;
        position: relative;
        overflow: auto;

        & .rstcustom__node{
            background-color: #FAFBFB;

            // &:first-of-type{
            //     display: none;
            // }

            &:nth-child(even){
                background-color: unset;
            }
            &:hover{
                background-color: #F0F9FD;
            }

            // & .rstcustom__rowWrapper .rstcustom__lineBlock:first-of-type{
            //     background-color: red;
            //     display: none;
            // }

            // Vertical line in last levels
            // & :not(:has(.rstcustom__expandButton)):not(:has(.rstcustom__collapseButton)) .rstcustom__rowWrapper .rstcustom__lineBlock:nth-last-child(2):before{
            &.currentNodeChildren .rstcustom__rowWrapper .rstcustom__lineBlock:nth-last-child(2):before{
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                transform: translateX(-50%);
                width: 1px;
                height: 100%;
                background: #0273A5;
            }
            
            // Remove vertical line when there are expand or collapse icons
            &.currentNodeChildren :has(.rstcustom__expandButton) .rstcustom__rowWrapper .rstcustom__lineBlock:nth-last-child(2):before,
            &.currentNodeChildren :has(.rstcustom__collapseButton) .rstcustom__rowWrapper .rstcustom__lineBlock:nth-last-child(2):before{
                content: '';
                background-color: unset;
            }
    
            // Same level lines
            &.currentNodeChildren :not(:has(.rstcustom__expandButton)):not(:has(.rstcustom__collapseButton)) .rstcustom__rowWrapper .rstcustom__lineBlock:nth-last-child(2):after{
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                transform: translateY(130%) translateX(3.5px) rotate(90deg);
                // transform: rotate(90deg);
                width: 1px;
                height: 7px;
                background: #0273A5;
            }
        }
        
        &.topSpace{
            .treeContent{
                margin-top: 20px;
                height: calc(100% - 30px);

                &.overflowed {
                    overflow: auto;
                }
            }
        }
        .buttonAll{
            border: none;
            background: none;
            position: relative;
            padding: 0;
            cursor: pointer;
            outline: none;
            margin-left: 1rem;
            margin-right: 30px;
            &:hover{
                h4{
                    text-decoration: underline;
                }
            }
            h4{
                color: #0273A5;
            }
        }
        .treeContent{
            flex: 1 0 50%;
            height: 100%;
            &.reducedTree{
                padding-top: 30px;
            }
            .rstcustom__row.rst__selected-node{
                width: 100%;
                background: #F0F7FA;
                .rstcustom__rowContents{
                    justify-content: left;
                }
            }

            &.overflowed {
                overflow: auto;
            }
        }
        .icons{
            &.disabled{
                fill: #ababab !important;
            }
            fill: #0273A5 !important;
            outline: none;
        }
    }
}
.rstcustom__rowSearchFocus{
    box-shadow: none;
}
.rstcustom__rowSearchMatch{
    border-bottom: 1px #0273A5;
}
.rstcustom__rowLabel{
    margin-left: 0.25rem;
    color:#0273A5;
    font-weight:bold !important;
}
.rstcustom__collapseButton, .rstcustom__expandButton{
    width: 20px;
    margin-left: 1rem;
    outline: none;
    height: 100%;
    &:after{
        border: solid #0273A5;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        top: 6px;
        left: 4px;
    }
}
.rstcustom__collapseButton:focus::after, .rstcustom__expandButton:focus::after{
    filter: none;
}

.rstcustom__expandButton::after{
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.rstcustom__collapseButton::after{
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    top: 9px;
}
.rstcustom__rowSearchMatch{
    box-shadow: none;
    // border-bottom: 1px solid #0273A5;
    .rstcustom__rowLabel span{
        background: yellow;
    }
}
.rstcustom__rowWrapper{
    &:hover{
        // background: #F0F7FA;
        opacity: 1;
        .treeOptions{
            display: block !important;
        }
    }
    > div{
        height: 100%;
    }
    .rstcustom__row{
        // top: 0.25rem;
        margin-left: -0.5rem;
        left: 1rem;
        &.disable-tree-line{
            *{
                color: #ababab !important;
            }
        }
        .rstcustom__rowToolbar{
            .treeOptions{
                padding: 0 ;
                margin-right: 10px;
                color: #0273A5;
                text-decoration: none;
                vertical-align: middle;
                font-weight: 100;
                cursor: pointer;
                display: none;
                &.type-info{
                    cursor: auto;
                    >span{
                        >span{
                            position: relative;
                            transform: none;
                            padding: 0 10px;
                        }
                    }
                }
                &:hover{
                    h5{
                        text-decoration: underline;
                    }
                }
                svg{
                    width: 16px;
                    height: 16px;
                    margin-right: 0.25rem;
                    display: inline-block;
                    vertical-align: middle;
                }
                h5{
                    display: inline-block;
                    vertical-align: middle;
                    line-height: normal;
                }
            }
        }
    }
}
