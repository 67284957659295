.sortable-item {
    pointer-events: auto !important;
    z-index: 9999;
}

.sortableHelper > .sortable-item:hover {
    cursor: grab;
}

body > .sortable-item:hover {
    cursor: grabbing;
    opacity: 0.8;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}